import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

const CustomComponent = ({ text }) => (
  <div>
    <img src="/marker-icon.png" />
  </div>
);

export default class GoogleMapComponent extends Component {
  static defaultProps = {
    center: {
      lat: 18.57002,
      lng: 73.78122,
    },
    zoom: 11,
  };

  render() {
    return (
      <div style={ { height: "100vh", width: "100%" } }>
        <GoogleMapReact
          bootstrapURLKeys={ { key: "AIzaSyB38uL6O7MMtdvbI4OFT0Tt-Nyb0uM4Dh0" } }
          defaultCenter={ this.props.center }
          defaultZoom={ this.props.zoom }
        >
          <CustomComponent lat={ 18.57001 } lng={ 73.78122 } text="Marker" />
        </GoogleMapReact>
      </div>
    );
  }
}
