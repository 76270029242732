import React, {useState} from "react";
import { useForm } from "react-hook-form";
import { Row, Col } from "../../ui/wrapper";
import Form, { FormGroup, Input, Textarea, Error } from "../../ui/form";
import Button from "../../ui/button";
import Heading from "../../ui/heading";
import Text from "../../ui/text";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ContactFormSuccessMessage from './ContactFormSuccessMessage';

const headingStyle = {
  as: "h3",
  position: "relative",
  pl: "4px",
  lineheight: 1.67,
  fontweight: 600,
  child: {
    color: "primary",
  },
  layout: "quote",
  mt: "15px",
};

const textStyle = {
  mt: "15px",
  ml: "4px",
  mb: "30px !important",
  fontSize: "14px",
  color: "#696969",
}

const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isFormSubmitted, setFormSubmitted] = useState(false);

  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = async ( { name, email, subject, message } ) => {
    if (!executeRecaptcha) {
      console.log("executeRecaptcha is null");
      return;
    }

    const recaptchaToken = await executeRecaptcha("contactus");
    const phone = 12345; //Dummy value

    const data = {
      name,
      email,
      subject,
      message,
      phone,
      recaptchaToken
    }

    fetch('/api/contact', {
      method: 'post',
      headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(res => {
        res.status === 200 ? setFormSubmitted(true) : setFormSubmitted(false);
        reset();
    }).catch((error) => {
        console.error('Error:', error);
        alert(error);
    });

  };

  if(isFormSubmitted){
    return <ContactFormSuccessMessage/>
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row gutters={{ lg: 20 }}>
        <Col>
          <Heading {...headingStyle} as="h5">
            Please fill the below information.
          </Heading>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col>
          <Text {...textStyle}>We will revert within 2 business days.</Text>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              {...register('name', { required: "Name is required" })}
              id="name"
              placeholder="Name *" />
            <Error>{errors?.name && errors?.name.message}</Error>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="email"
              {...register('email', {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address",
                },
              })}
              id="email"
              placeholder="Email *" />
            <Error>{errors?.email && errors?.email?.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <FormGroup mb="20px">
            <Input
              type="text"
              {...register('subject', { required: "Subject is required" })}
              id="subject"
              placeholder="Subject *" />
            <Error>{errors?.subject && errors?.subject?.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              {...register('message', {
                required: "Message is required",
                maxLength: {
                  value: 50,
                  message: "Maximum length is 50",
                },
                minLength: {
                  value: 10,
                  message: "Minimum length is 10",
                },
              })}
              id="message"
              placeholder="Please describe what you need."></Textarea>
            <Error>{errors?.message && errors?.message?.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Button type="submit"
          skin="secondary"
          hover={{bgColor: 'primary'}}
          >Send Message</Button>
        </Col>
      </Row>

    
    </Form>
  );
};

export default ContactForm;
