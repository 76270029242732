import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "../../../components/ui/wrapper";
import Heading from "../../../components/ui/heading";
import ContactForm from "../../../components/forms/contact-form";
import { ContactFormWrapper, LeftBox } from "./contact-form-area.style";
import AppReCaptchaProvider from '../../../components/recaptcha';

const ContactFormArea = ({ headingStyle, textStyle }) => {
  return (
    <ContactFormWrapper>
      <Container>
        <Row alignitems="center">
          <Col lg={12}>
            <LeftBox>
              <Heading {...headingStyle}>
                <span>Contact Us</span>
              </Heading>
             {/*  <Heading {...headingStyle} as="h5">
                Please fill the below information.
              </Heading>
              <Text {...textStyle}>We will revert within 2 business days.</Text> */}
            </LeftBox>
          </Col>
        </Row>
        <Row alignitems="center">
          <Col lg={12}>
            <AppReCaptchaProvider>
              <ContactForm />
            </AppReCaptchaProvider>
          </Col>
        </Row>
      </Container>
    </ContactFormWrapper>
  );
};

ContactFormArea.propTypes = {
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

ContactFormArea.defaultProps = {
  headingStyle: {
    as: "h3",
    position: "relative",
    pl: "4px",
    lineheight: 1.67,
    fontweight: 600,
    child: {
      color: "primary",
    },
    layout: "quote",
    mt: "15px",
  },
  textStyle: {
    mt: "15px",
    ml: "4px",
    mb: "30px !important",
    fontSize: "14px",
    color: "#696969",
  },
};

export default ContactFormArea;
