import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import Header from "../containers/layout/header/header-one";
import Footer from "../containers/layout/footer/footer-one";
import PageHeader from "../components/pageheader";
import { Container, Row, Col } from "../components/ui/wrapper";
import CTAArea from "../containers/global/cta-area/section-one";
import ContactFormArea from "../containers/contact-us/contact-form-area";
import ContactInfoArea from "../containers/contact-us/contact-info-area";
import GoogleMapComponent from "../components/forms/contact-form/GoogleMapComponent";

const seo = {
  title: "Contact Us",
  description: "Contact Us",
  image: "/dark-logo.png",
  pathname: "/contact-us",
};

const ContactUsPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO
      title={seo.title}
      description={seo.description}
      image={`${seo.image}`}
      pathname={seo.pathname}
    />   
    <Header/>
    <PageHeader
      pageContext={pageContext}
      location={location}
      title="Contact Us"
    />
    <main className="site-wrapper-reveal">
      <Container>
        <Row alignitems="center">
          <Col lg={4}>
            {/* <ContactInfoArea/> */}
          </Col>
          <Col lg={8}>
            <ContactFormArea/>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <GoogleMapComponent />
          </Col>
        </Row>
      </Container>
    </main>
    <Footer/>
  </Layout>
)

export default ContactUsPage