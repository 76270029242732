import React from "react";
import styled, { css } from "styled-components";
import { Col } from "../../ui/wrapper";
import Button from "../../ui/button";

export const SuccessTitle = styled.h5`
  font-weight: 400;
  line-height: 1.3;
  color: rgb(68, 68, 67);
  margin-top: 32px;
  margin-bottom: 16px;
  font-size: 2.25rem;
`;

const ContactFormSuccessMessage = () => {

    return (
        <>
            <SuccessTitle>
                Thank you! We have received these details. 
                <br/>We will revert in the next 1 business day.
            </SuccessTitle>
            <Col lg={12}>
            <Button 
                mt="30px" 
                hover={3}
                to={"/"}
            >
                Ok, Go Back
            </Button>
            </Col>
        </>
    )
}

export default ContactFormSuccessMessage;