import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import {Container, Row, Col} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import Anchor from '../../../components/ui/anchor'
import {ContactInfoWrapper, InfoBoxWrap, InfoBox} from './contact-info-area.style';


const ContactInfoArea = ({headingStyle, textStyle, phoneStyle}) => {
    const contactInfo = useStaticQuery(graphql `
        query {
            site {
                siteMetadata {
                    contact {
                        address {
                            line1,
                            line2,
                            locality,
                            city,
                            country,
                            pin
                        }
                        email
                        phone
                        addressInfos {
                            id
                            state
                            address
                            email
                            phone
                        }
                    }
                }
            }
        }      
    `);
    const info = contactInfo.site.siteMetadata.contact;
    return (
        <ContactInfoWrapper>
            <Container>
                <Row>
                    {info && (
                        <Col lg={12}>
                            <InfoBoxWrap>
                                <Heading {...headingStyle}>Synergenie Solutions</Heading>
                                <InfoBox>
                                    {info.address && (
                                        <>
                                            <Text {...textStyle}>{info.address.line1},</Text>
                                            <Text {...textStyle}>{info.address.line2},</Text>
                                            <Text {...textStyle}>{info.address.locality}, {info.address.city}</Text>
                                            <Text {...textStyle}>{info.address.country} - {info.address.pin}</Text>
                                        </>
                                    )}
                                    {info.email && <Anchor {...textStyle} path={`mailto:${info.email}`}>{info.email}</Anchor>}
                                    {info.phone && <Text {...textStyle}><Anchor {...phoneStyle} path={`tel:${info.phone}`}>{info.phone}</Anchor></Text>}
                                </InfoBox>
                            </InfoBoxWrap>
                        </Col>
                    )}
                </Row>
            </Container>
        </ContactInfoWrapper>
    )
}

ContactInfoArea.propTypes = {
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object,
    phoneStyle: PropTypes.object,
}

ContactInfoArea.defaultProps = {
    headingStyle: {
        as: "h5",
        mb: "20px"
    },
    textStyle: {
        mb: "7px"
    },
    phoneStyle: {
        fontweight: 800,
        color: 'headingColor'
    }
}

export default ContactInfoArea;